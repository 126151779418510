import React, { useState, useRef } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  screen,
  innerWidth,
  mainWhite,
  transHover,
  highlight,
  mainBlack,
} from "../components/common/variables";
import { toSlug } from "../functions";
import { format } from "date-fns";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
    padding-bottom: 120px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 180px;
    padding-top: 270px;
  }
  @media ${screen.xlarge} {
    padding-top: 328px;
    padding-left: 0;
    padding-right: 0;
  }
  .news {
    &__heading {
      font-size: 2.1rem;
      @media ${screen.small} {
        font-size: 2.7rem;
      }
    }

    .filter-section {
      margin-top: 30px;
      @media ${screen.small} {
        margin-top: 35px;
      }
      @media ${screen.medium} {
        margin-top: 60px;
      }

      &__heading {
        font-size: 1.3rem;
      }

      &__filter-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 1000px;
        margin-top: 20px;

        li {
          font-size: 1.05em;
          font-weight: 300;
          margin-right: 10px;
          margin-bottom: 15px;
          @media ${screen.small} {
            font-size: 1.25rem;
            margin-right: 22px;
            margin-bottom: 20px;
          }
          @media ${screen.medium} {
            font-size: 1.42rem;
            margin-right: 20px;
            margin-bottom: 18px;
          }

          .category {
            cursor: pointer;
            transition: ${transHover};

            &:hover {
              color: ${highlight};
            }

            &--active {
              color: ${highlight};
            }
          }

          .slash {
            padding-left: 5px;
            @media ${screen.small} {
              padding-left: 15px;
            }
          }

          &:last-child {
            .slash {
              visibility: hidden;
            }
          }
        }
      }
    }

    .blogs-container {
      margin-top: 5px;

      &__blog-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;

        .blog-item {
          overflow: hidden;
          position: relative;
          margin: 20px 0 35px 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media ${screen.small} {
            width: 48%;
            margin: 20px 0 70px 0;
          }
          @media ${screen.medium} {
            width: 31.4%;
          }
          @media ${screen.large} {
            width: 32.5%;
          }

          &__featured-img {
            position: relative;
            cursor: pointer;
            width: 100%;

            img {
              width: 100%;
              display: block;
            }

            @media ${screen.withCursor} {
              &::after {
                content: "";
                background: rgba(134, 206, 207, 0.9);
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                transition: ${transHover};
                opacity: 0;
              }
            }

            &:hover ::after {
              opacity: 1;
            }
          }

          &__title {
            font-size: 1.2rem;
            font-weight: 700;
            max-width: 440px;
            margin-top: 10px;
            @media ${screen.small} {
              margin-top: 20px;
              font-size: 1.3rem;
            }

            @media ${screen.large} {
              margin-top: 25px;
            }
          }

          &__date {
            color: ${highlight};
            font-size: 1rem;
            margin: 3px 0;
            @media ${screen.small} {
              font-size: 1.1rem;
              margin: 6px 0;
            }

            span {
              cursor: pointer;
            }
          }

          &__description {
            color: ${mainBlack};
            font-size: 1.1rem;
            font-weight: 300;
            max-width: 470px;
            margin-bottom: 20px;
            @media ${screen.small} {
              margin-bottom: 30px;
            }
          }

          &__link {
            background: ${mainBlack};
            display: table;
            color: ${mainWhite};
            font-size: 1.1rem;
            padding: 14px 30px 12px 30px;
            transition: ${transHover};
            margin-top: 0;
            @media ${screen.small} {
              margin-top: auto;
            }
            &:hover {
              background: ${highlight};
            }
          }
        }

        &:after {
          content: " ";
          visibility: hidden;
          width: 100%;
          @media ${screen.small} {
            width: 49%;
          }
          @media ${screen.medium} {
            width: 32.5%;
          }
        }
      }

      &__paginate {
        display: flex;
        margin-top: 20px;

        .box {
          display: block;
          cursor: pointer;
          width: 38px;
          height: 40px;
          border: 1px ${mainBlack} solid;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: ${transHover};

          &:hover {
            border: 1px ${highlight} solid;
          }

          &:hover .body {
            fill: ${highlight};
            transition: ${transHover};
          }

          &:hover .page-num {
            color: ${highlight};
            transition: ${transHover};
          }

          .page-num {
            font-size: 1.1rem;
          }

          &--active {
            background: ${highlight};
            border: 1px ${highlight} solid;
            pointer-events: none;
            &:hover .page-num {
              color: ${mainWhite};
            }
            .page-num {
              color: ${mainWhite};
            }
          }
        }
      }
      &__page-guide {
        align-self: center;
        font-size: 0.85rem;
        margin-top: 5px;
        @media ${screen.large} {
          display: none;
        }
      }
    }
  }
`;

const NewsPage = ({ data }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const list = useRef(null);
  const allBlog = data.content.edges;

  // get all categories from Primic blog tags and sort it
  const categories = data.content.edges
    .reduce((a, c) => {
      return a.concat(c.node.tags);
    }, [])
    // filter repeated value
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort();

  const renderBlogs =
    selectedCategory === "All"
      ? allBlog
      : allBlog.filter((blog) =>
          blog.node.tags.some((tag) => tag === selectedCategory)
        );

  return (
    <Layout>
      <SEO title="News" />
      <Wrapper>
        <div className="news">
          <h1 className="news__heading">NEWS</h1>
          <div className="filter-section">
            <ul className="filter-section__filter-list">
              <li>
                <span
                  className={
                    selectedCategory === `All`
                      ? `category category--active`
                      : `category`
                  }
                  onClick={() => {
                    if (selectedCategory !== `All`) {
                      setSelectedCategory(`All`);
                    }
                  }}
                >
                  All
                </span>{" "}
                <span className="slash"> / </span>
              </li>
              {categories.map((category) => (
                <li key={category}>
                  <span
                    className={
                      selectedCategory === category
                        ? `category category--active`
                        : `category`
                    }
                    onClick={() => {
                      if (selectedCategory !== category) {
                        setSelectedCategory(category);
                      }
                    }}
                  >
                    {category}
                  </span>
                  <span className="slash"> / </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="blogs-container">
            <ul className="blogs-container__blog-list" ref={list}>
              {renderBlogs.map((item, i) => (
                <li className="blog-item" key={i}>
                  <Link
                    className="blog-item__featured-img"
                    to={`/news/${toSlug(item.node.data.title.text)}`}
                  >
                    <img
                      src={
                        item.node.data.featured_image.thumbnails.thumbnail.url
                      }
                      alt="featured"
                    />
                  </Link>
                  <Link to={`/news/${toSlug(item.node.data.title.text)}`}>
                    <h3 className="blog-item__title">
                      {item.node.data.title.text}
                    </h3>
                  </Link>

                  <p className="blog-item__date">
                    {item.node.tags.map((cat, i) => (
                      <span
                        key={i}
                        onClick={() => setSelectedCategory(cat)}
                      >{` / ${cat}`}</span>
                    ))}
                  </p>
                  <p className="blog-item__description">
                    {item.node.data.featured_description}
                  </p>
                  <Link
                    className="blog-item__link"
                    to={`/news/${toSlug(item.node.data.title.text)}`}
                  >
                    Read More
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NewsPage;

export const dataQuery = graphql`
  {
    content: allPrismicBlog(
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          tags
          first_publication_date
          data {
            title {
              text
            }
            featured_image {
              alt
              thumbnails {
                thumbnail {
                  alt
                  url
                }
              }
            }
            featured_description
          }
        }
      }
    }
  }
`;
