import slugify from "slugify";

// function helpers
export const setHash = (val = "") => {
  window.location.hash = val;
};

export const getVimeoId = (url) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};

export const reduceWords = (str, length = 31) => {
  return str.split(" ").slice(0, length).join(" ") + "...";
};

export const toSlug = (string) => {
  return slugify(string, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
  });
};

// returned array of arrays based only on prismic returned data
export const flattenArr = (arr) => {
  const allCategory = [].concat.apply([], arr);
  // remove null or undefined value from prismic
  const filterNull = allCategory.filter((item) => item);
  // filter out repeated value
  const removedRepeatedValue = filterNull
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort();

  // final clean array to use for categories
  return removedRepeatedValue;
};
